#dtOpenClaims > div.p-paginator.p-component.p-unselectable-text.p-paginator-bottom > div {
    vertical-align: top;
}

#dtOpenClaims > div.p-paginator.p-component.p-unselectable-text.p-paginator-bottom > div > label {
    width: 70px;
}

#dtOpenClaims
> div.p-paginator.p-component.p-unselectable-text.p-paginator-bottom
> span.p-paginator-pages
> button.p-paginator-page.p-paginator-element.p-link.p-highlight {
    font-size: 0.8em;
}
