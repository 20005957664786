.p-panelmenu .p-panelmenu-header > a {
    border: none!important;
    background: none!important;
    color: white!important;
}

.p-panelmenu .p-panelmenu-header.p-highlight > a {
    border: none!important;
    background: none!important;
    color: white!important;
}

.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover {
    border: none!important;
    background: none!important;
    color: white!important;
}

.p-panelmenu .p-panelmenu-content {
    border: none!important;
    background: none!important;
    color: white!important;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
    border: none!important;
    background: none!important;
    color: white!important;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon {
    background: none!important;
    color: white!important;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: white!important;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    color: white!important;
}

.p-panelmenu .p-panelmenu-header > a:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    color: white!important;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
    color: white!important;
}

.p-panelmenu .p-panelmenu-content {
    margin-left: 1.5rem;
}
