div[class^='Nav__navBar'] {
    display: none;
}

body {
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
}

div {
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
}

.p-datatable-tbody {
    font-size: 0.8em;
}

.card {
    padding: 16px;
}

ul.layout-menu.layout-main-menu.clearfix li a i.fa {
    font-size: 2.7em;
}

.alternateCard {
    background-image: linear-gradient(to left, rgba(69, 127, 202, 0.55), rgba(69, 127, 202, 0.65));
}

.alternateCardHeader {
    background-image: linear-gradient(to right, rgba(33, 37, 41, 0.95), rgba(69, 127, 202, 0.65));
    color: white !important;
}

div.p-panel.alternateCard div.p-panel-titlebar {
    background-image: linear-gradient(to right, rgba(33, 37, 41, 0.95), rgba(69, 127, 202, 0.65));
    color: white !important;
}

.ui-panel-title {
    line-height: 1.5em;
    margin: 0;
    font-size: 1.143em;
}

.ui-panel-titlebar i {
    margin-right: 5px;
}

label.textInputLabel {
    font-weight: bold;
    margin-right: 10px;
}

input.inputTextControl {
    margin-right: 20px;
}

div.newMsgDialog div.ui-dialog-content.ui-widget-content {
    overflow: visible;
}

.newMsgDialog {
    width: 600px;
    height: 400px;
}

ul.messagesMenu.layout-menu.fadeInDown {
    width: 320px;
}

span.versionNumber {
    color: white;
    text-align: right;
    font-size: 10px;
    float: right;
    padding-right: 20px;
    padding-bottom: 10px;
}

li.subMenuItem {
    font-size: 9px;
}

tr.ui-datatable-even.ui-state-highlight > td > span > a,
tr.ui-datatable-odd.ui-state-highlight > td > span > a {
    color: #6bb2ff;
    font-weight: bold;
}

span.mainNavMessageMenuText {
}

span.LoadableComponent {
    text-align: center;
    width: 100%;
}

body .p-datatable .p-sortable-column.p-highlight {
    background-color: #8ab1db27;
    color: black;
    border-top-color: #8ab1db27;
}

body .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: black;
}

ul.NoBullets {
    list-style: none;
}

ul.NoBullets > li {
    padding-top: 4px;
}

body p-sortable-column p-highlight {
    background-color: red !important;
}

li.PaddedListItem {
    margin-top: 14px !important;
}

/* Issue with using an unmigrated version of Avalon with the newest primereact
  see: https://forum.primefaces.org/viewtopic.php?f=71&t=61098&sid=20c46df1aafadc03e28457f9222df73f
   */
.p-dialog-mask.p-component-overlay {
    background-color: rgba(0, 0, 0, 0.4);
    opacity: 1;
}

tr.p-datatable-row > td {
    text-overflow: ellipsis;
    overflow: hidden;
}

tbody.p-datatable-tbody > tr > td {
    text-overflow: ellipsis;
    overflow: hidden;
    border: 1px solid #dee2e6 !important;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
}

.p-inputtext.p-component.p-column-filter {
    width: 80px;
}

.mainHeader {
    min-height: 50px;
    padding-bottom: 20px;
    border-right: 1px solid #dee2e6;
    border-top: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
    padding: 15px;
    background-color: #2a313c;
    color: white;
}

.subHeader {
    background-color: #4e88c9;
    color: #fdfeff;
    font-size: 20px;
    min-height: 50px;
    font-weight: bold;
    padding-left: 20px;
    padding-top: 10px;
}

.p-accordion-header .p-accordion-header-link {
    background-color: #4e88c9 !important;
    color: #fdfeff !important;
    max-height: 50px;
    font-size: 20px;
    font-weight: bold;
}

.d-flex {
    display: flex!important;
}

.p-panel-header {
    color: white!important;
    background-color: #4e88c9!important;
    border-radius: 5px;
}

@media screen and (max-width: 1367px) and (min-width: 768px) {
    .lg-md\:col-3 {
        flex: 0 0 auto;
        padding: 0.5rem;
        width: 25%;
    }
    .lg-md\:col-4 {
        flex: 0 0 auto;
        padding: 0.5rem;
        width: 33.3333%;
    }
    .lg-md\:col-6 {
        flex: 0 0 auto;
        padding: 0.5rem;
        width: 50%;
    }
    .lg-md\:col-8 {
        flex: 0 0 auto;
        padding: 0.5rem;
        width: 66.6666%;
    }
    .lg-md\:col-12 {
        flex: 0 0 auto;
        padding: 0.5rem;
        width: 100%;
    }
}

.p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #dee2e6 !important;
    border-width: 0 0 1px 0;
    font-weight: 700;
    color: #343a40;
    background: #f8f9fa;
    transition: box-shadow 0.2s;
}

.disabled {
    pointer-events: none;
    opacity: 0.4;
}

.swal2-container {
    z-index: 10000 !important;
}

.p-inputtext.p-component.p-column-filter {
    width: 100%
}

.p-inputswitch .p-inputswitch-slider:before {
    border-radius: 30px !important;
}

.p-inputswitch .p-inputswitch-slider {
    border-radius: 30px !important;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}
@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}
@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}


.disabled-pointer {
    pointer-events: none;
}
